<template>
  <b-card no-body>
    <b-row class="m-2">
      <b-col>
        <div class="d-flex justify-content-between align-items-center">
          <h2>Top-Up Your Account</h2>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :to="{ name: 'buy-credits' }"
            class="rounded-pill"
            variant="primary"
        >
            <feather-icon
            icon="ArrowLeftCircleIcon"
            class="mr-50"
            />
            <span class="align-middle">Back</span>
        </b-button>
        </div>
        <p class="mt-1 mb-2">Follow the instructions to top-up your account</p>

        <b-tabs pills>
            <hr />
            <b-tab
                title="Top-Up Instantly"
                active
            >
                <mpesa-topup-instantly />
            </b-tab>
            <b-tab title="Top-Up via Paybill">
                <mpesa-topup-paybill />
            </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCol, BRow, BButton, BTab, BTabs } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import MpesaTopupInstantly from './components/MpesaTopupInstantly'
import MpesaTopupPaybill from './components/MpesaTopupPaybill'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BTab,
    BTabs,
    MpesaTopupInstantly,
    MpesaTopupPaybill,
  },
};
</script>
