<template>
  <b-row class="align-items-center">
    <b-col xs="12" md="6">
      <validation-observer
        ref="mpesaTopUpInstantlyFormValidator"
        #default="{ invalid }"
      >
        <b-form
          @submit.prevent="mpesaTopUpInstantly"
          ref="mpesaTopUpInstantlyForm"
        >
          <!-- phone number -->
          <b-form-group label="Enter MPESA Phone Number" label-for="phone">
            <validation-provider
              #default="{ errors }"
              name="Phone"
              vid="phone"
              rules="required|integer"
            >
              <b-form-input
                id="phone"
                type="tel"
                v-model="phone"
                placeholder="1234 567 8900"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- last name -->
          <b-form-group
            label-for="amount"
            label="Amount in Kenya Shillings (KSH)"
          >
            <validation-provider
              #default="{ errors }"
              name="Amount"
              vid="amount"
              rules="required|integer|min:3"
            >
              <b-form-input
                id="amount"
                v-model.number="amount"
                type="number"
                name="amount"
                placeholder="XXXX"
              />
              <small class="text-mute">Minimum transaction amount is KSH 100</small>
              <br/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            block
            :disabled="invalid || formIsLoading || paymentInProgress"
            :class="{ disabled: invalid }"
          >
            <b-spinner small v-if="formIsLoading" />
            <span v-if="formIsLoading">&nbsp;</span>
            Top-Up
          </b-button>
        </b-form>
      </validation-observer>
    </b-col>

    <b-col xs="12" md="6" v-if="paymentStatus">
      <b-card
        border-variant="primary"
        bg-variant="transparent"
        class="mt-3 mt-md-0"
        v-if="paymentStatus == 'pending'"
      >
        <b-card-title
          class="text-primary d-flex align-items-center justify-content-between"
        >
          <span>Payment Status: Pending</span>
          <b-spinner variant="primary" type="grow" />
        </b-card-title>

        <b-progress animated value="100" class="progress-bar-primary mb-2" />

        <b-card-text v-html="paymentMessage"> </b-card-text>
      </b-card>
      <b-card
        border-variant="success"
        bg-variant="transparent"
        class="mt-3 mt-md-0"
        v-if="paymentStatus == 'completed'"
      >
        <b-card-title
          class="text-success d-flex align-items-center justify-content-between"
        >
          <span>Payment Status: Received</span>
          <feather-icon icon="CheckCircleIcon" class="mr-50 icon-status" />
        </b-card-title>

        <b-card-text v-html="paymentMessage"> </b-card-text>
      </b-card>
      <b-card
        border-variant="danger"
        bg-variant="transparent"
        class="mt-3 mt-md-0"
        v-if="paymentStatus == 'error'"
      >
        <b-card-title
          class="text-danger d-flex align-items-center justify-content-between"
        >
          <span>Payment Status: Unsuccessfully </span>
          <feather-icon icon="AlertCircleIcon" class="mr-50 icon-status" />
        </b-card-title>

        <b-card-text v-html="paymentMessage"> </b-card-text>
      </b-card>
      <b-card
        border-variant="warning"
        bg-variant="transparent"
        class="mt-3 mt-md-0"
        v-if="paymentStatus == 'no-response'"
      >
        <b-card-title
          class="text-warning d-flex align-items-center justify-content-between"
        >
          <span>Payment Status: No Response Received </span>
          <feather-icon icon="AlertCircleIcon" class="mr-50 icon-status" />
        </b-card-title>

        <b-card-text v-html="paymentMessage"> </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>


<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, min } from "@validations";

import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
  BCard,
  BCardText,
  BCardTitle,
  BProgress,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    BCard,
    BCardText,
    BCardTitle,
    BProgress,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      phone: null,
      amount: null,
      formIsLoading: false,
      requestId: null,
      paymentStatus: null,
      paymentMessage: null,
      paymentInProgress: false,
      statusInterval: null,
      required,
      integer,
      min,
    };
  },
  methods: {
    mpesaTopUpInstantly() {
      this.formIsLoading = true;
      this.paymentInProgress = false;
      this.$refs.mpesaTopUpInstantlyFormValidator.validate().then((success) => {
        if (success) {
          this.$http
            .post("/credits/mpesa-instantly", {
              phone: this.phone,
              amount: this.amount,
            })
            .then((response) => {
              this.formIsLoading = false;
              this.paymentInProgress = true;
              this.requestId = response.data.data.id;
              this.paymentStatus = "pending";
              this.paymentMessage = response.data.data.message;

              this.statusInterval = setInterval(() => {
                this.checkTransactionStatus();
              }, 5000);

              setTimeout(() => {
                clearInterval(this.statusInterval);
                this.paymentInProgress = false;
                if (this.paymentStatus == "pending") {
                  this.paymentStatus = "no-response";
                  this.paymentMessage =
                    "No response was received, kindly try again. ";
                }
              }, 40000);

              this.$nextTick(() => {
                this.$refs.mpesaTopUpInstantlyFormValidator.reset();
              });
              this.$refs.mpesaTopUpInstantlyForm.reset();
            })
            .catch((error) => {
              for (let err of error.response.data.error) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: err,
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
              }
              this.formIsLoading = false;
            });
        }
      });
    },
    checkTransactionStatus() {
      this.$http
        .get("/credits/transaction-status/" + this.requestId)
        .then((response) => {

          if (response.data.data.status == "completed") {
            this.$http.get("/user/balance").then((res) => {
              clearInterval(this.statusInterval);
              localStorage.setItem("balance", res.data.data);
              this.$root.$emit("update-user-balance");
              this.paymentStatus = response.data.data.status;
              this.paymentMessage = response.data.data.message;
              this.paymentInProgress = false;
            });
            

          } else if (response.data.data.status == "error") {
            clearInterval(this.statusInterval);
            this.paymentStatus = response.data.data.status;
            this.paymentMessage = response.data.data.message;
            this.paymentInProgress = false;

          } else if (response.data.data.status == "pending") {
            if(this.paymentStatus != "no-response"){
              this.paymentStatus = response.data.data.status;
            }
          }

        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: error.Error(),
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
          this.paymentInProgress = false;
        });
    },
  },
};
</script>

<style scoped>
.icon-status {
  width: 2rem;
  height: 2rem;
}
</style>