<template>
  <ol>
    <li>Go to the M-Pesa menu</li>
    <li>Select Payment services</li>
    <li>Select Pay Bill</li>
    <li>Enter <strong>4036793</strong></li>
    <li>Enter <strong>{{user.phone}}</strong> as the account number.</li>
    <li>Enter amount in KSH</li>
    <li>Enter M-PESA PIN</li>
    <li>You will receive notification from M-PESA with a confirmation code</li>
    <li>Well done! Your account will be topped-up automatically</li>
  </ol>
</template>

<script>
import { getUserData } from "@/auth/utils";

export default {
  data() {
    return {
      user: null,
    };
  },

  created() {
    this.user = getUserData();
  },
};
</script>

<style scoped>
li {
  font-size: 1.2rem;
  margin: 0.7rem 0;
}
</style>
